import { HeadlessUI } from '@systemeio/ui-shared'
import NotificationMenuMobile from 'modules/notifications/components/notifications-menu-mobile'
import { useRouter } from 'next/router'
import React, { Fragment, useEffect } from 'react'
import SideMenuItem from 'shared/components/header/components/side-menu/components/side-menu-item'
import WorkspaceSelector from 'shared/components/header/components/workspace-selector'
import { useMenuItems } from 'shared/components/header/hooks/use-menu-items'
import { MobileLanguageSwitcher } from 'shared/components/language-switcher/mobile-language-switcher'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import { ScreenHeightDiv } from 'shared/components/screen-height-div'
import { ABSOLUTE_PATH_PREFIX } from 'shared/constants/base-path-url'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import ArrowLeftIcon from 'shared/icons/arrow-left-icon'
import BurgerIcon from 'shared/icons/burger-icon'
import LogoutIcon from 'shared/icons/logout-icon'

interface SideMenuProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const SideMenu = ({ isOpen, setIsOpen }: SideMenuProps) => {
  const {
    isWorkspaceOwner,
    user,
    dashboardUser,
    userStatus,
    checkAdminRoles,
    isLoading,
    isDisabled,
  } = useUser()
  const { getMenuItems, upgradeAccountItem } = useMenuItems()
  const { t } = useLocoTranslation()
  const { pathname } = useRouter()

  const isActive = pathname.includes('/profile')

  useEffect(() => {
    setIsOpen(false)
  }, [pathname, setIsOpen])

  return (
    <div
      className={`${
        isLoading && 'animate-pulse'
      } w-full justify-end flex gap-5 lg:hidden items-center`}
    >
      <NotificationMenuMobile />
      <button disabled={isLoading} aria-label="Open Menu" onClick={() => setIsOpen(true)}>
        <BurgerIcon className="w-8 h-8" />
      </button>
      <HeadlessUI.Transition show={isOpen} as={Fragment}>
        <HeadlessUI.Dialog className="fixed inset-0 z-10" onClose={() => setIsOpen(false)}>
          <HeadlessUI.TransitionChild
            as={Fragment}
            enter="ease duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <HeadlessUI.DialogBackdrop className="fixed inset-0 bg-black/50" />
          </HeadlessUI.TransitionChild>

          <HeadlessUI.TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-x-full"
            enterTo="opacity-100 translate-x-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-full"
          >
            <HeadlessUI.DialogPanel>
              <ScreenHeightDiv className="fixed max-w-[350px] top-0 right-0 overflow-auto z-10 min-w-[17rem] bg-white text-darkblue  py-5 px-4 flex flex-col">
                <div
                  onClick={() => setIsOpen(false)}
                  className="flex items-center gap-2 cursor-pointer w-fit"
                >
                  <ArrowLeftIcon className="stroke-darkblue" />
                  <span>{t('global.back_to_page')}</span>
                </div>
                {!isDisabled && user && (
                  <div className="flex justify-between gap-3.5 py-6">
                    {isWorkspaceOwner ? (
                      <LinkWithoutPrefetch
                        className="flex items-center gap-3.5"
                        href={'/profile/user-settings'}
                      >
                        <img
                          className="h-10 w-10 rounded-full object-cover"
                          src={user.avatarUrl}
                          alt={`${user.firstName} ${user.lastName}`}
                        />
                        <div className="flex flex-col">
                          <span className="font-semibold text-base">
                            {user.firstName} {user.lastName}
                          </span>
                          <span className={isActive ? 'text-blue' : ''}>
                            {t('global.settings')}
                          </span>
                        </div>
                      </LinkWithoutPrefetch>
                    ) : (
                      <LinkWithoutPrefetch
                        href={'/profile/account-settings'}
                        className="flex items-center gap-3.5"
                      >
                        <img
                          className="h-10 w-10 rounded-full object-cover"
                          src={user.avatarUrl}
                          alt={`${user.firstName} ${user.lastName}`}
                        />
                        <div className="flex flex-col">
                          <span className="font-semibold text-base">
                            {user.firstName} {user.lastName}
                          </span>
                          <span>{t('global.settings')}</span>
                        </div>
                      </LinkWithoutPrefetch>
                    )}
                    <MobileLanguageSwitcher />
                  </div>
                )}
                <div className="flex flex-col gap-8 py-4">
                  {user && !isDisabled && (
                    <>
                      {user.userPlanId === 40 && isWorkspaceOwner && !user.impersonator && (
                        <SideMenuItem item={upgradeAccountItem} />
                      )}
                      {getMenuItems(userStatus).map((item, idx) => {
                        if (item.item.isAdmin && !checkAdminRoles()) {
                          return
                        }
                        return <SideMenuItem key={idx} item={item.item} />
                      })}
                    </>
                  )}
                </div>
                <div className="mt-auto flex flex-col gap-3">
                  <WorkspaceSelector
                    afterChange={() => setIsOpen(false)}
                    className={'text-darkblue'}
                  />
                  {dashboardUser?.impersonator && (
                    <a
                      href={`${ABSOLUTE_PATH_PREFIX}/dashboard/admin/management/user/list?pretend_this_user=_exit`}
                    >
                      <p className="cursor-pointer px-2">{t('header.exit_impersonation')}</p>
                    </a>
                  )}
                  <div className="flex items-center">
                    <SideMenuItem
                      item={{
                        mobileIconRenderer: () => <LogoutIcon />,
                        href: `${ABSOLUTE_PATH_PREFIX}/dashboard/logout`,
                        name: t('header.user.profile.menu.logout'),
                      }}
                    />
                  </div>
                </div>
              </ScreenHeightDiv>
            </HeadlessUI.DialogPanel>
          </HeadlessUI.TransitionChild>
        </HeadlessUI.Dialog>
      </HeadlessUI.Transition>
    </div>
  )
}

export default SideMenu
